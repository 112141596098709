import * as React from 'react'

// Types
import { IconSize } from '@/lib/enums'

function Npm({
  color = 'currentColor',
  size = IconSize.Large,
  className
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M2 2H3H21H22V3V21V22H21H14H3H2V21V3V2ZM4 4V20H13V9H15V20H20V4H4Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default React.memo(Npm)
